import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

import {
  Container,
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core'

import { getHeroStyles } from '../styles/hero'

import windmill from '../../src/images/windmill.svg'

// styles
const styles = (theme) => {
  const heroStyles = getHeroStyles()
  return createStyles({
    section: {
      ...heroStyles,
      marginBottom: '5.8125rem',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        // marginBottom: '5.5625rem',
        padding: 0,
      },
    },
    notFoundText: {
      fontFamily: 'Montserrat',
      fontWeight: 800,
      color: '#F0EDEF',
      fontSize: '30px',
      marginBottom: '35px',
      [theme.breakpoints.up('md')]: {
        fontSize: '48px',
      },
    },
    doesNotExistText: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      color: '#F0EDEF',
      fontSize: '16px',
      marginBottom: '35px',
      [theme.breakpoints.up('md')]: {
        fontSize: '26px',
      },
    },
    button: {
      padding: '0.425rem',
      width: '200px',
      height: "35px",
      fontSize: "14px",
      fontFamily: 'Montserrat',
      backgroundColor: '#FF0080',
      color: '#fff',
      borderRadius: 100,
      border: 0,
      cursor: 'pointer',
      fontWeight: '700',
    },
    svg: {
      width: '100px',
    },
  })
}

// markup
const NotFoundPage = ({ classes }) => {
  return (
    <Container className={classes.section}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <object className={classes.svg} type="image/svg+xml" data={windmill} />
      </div>
      <p className={classes.notFoundText}>
        Page Not Found
      </p>
      <p className={classes.doesNotExistText}>
        The page you are looking for does not exist.
      </p>
      <Link to="/">
        <button className={classes.button}>
          Go Home
        </button>
      </Link>
    </Container>
  )
}

export default withStyles(styles)(NotFoundPage)
